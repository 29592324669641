import { getLibrarySystemClient } from "@libry-content/integrations";
import { isNormalSite } from "@libry-content/common";
import { useMemo } from "react";
import { useCommonData } from "../../components/layout/CommonDataProvider";

export const useLibrarySystemClient = () => {
  const { site } = useCommonData();
  const librarySystem = useMemo(
    () => (isNormalSite(site) ? getLibrarySystemClient(site.librarySystem) : undefined),
    [site]
  );
  return librarySystem;
};
