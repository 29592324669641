import fetch from "cross-fetch";

export interface LocalizedValue {
  nob: string;
  nno: string;
}

export interface Creator {
  role: string;
  type: string;
  name: string;
  id: string;
  dates: string;
}

export interface Genre {
  name: LocalizedValue;
  vocabulary: string;
  id?: string;
}

export interface Image {
  thumbnailUrl: string;
  url: string;
}

export interface Subject {
  name: LocalizedValue;
  id: string;
  vocabulary: string;
}

interface PublicationWork {
  id: string;
}

export interface BibliographicRecord {
  modified: Date;
  url: string;
}

const bibbiPublicationTypes = ["Book", "Audiobook", "VideoGame", "Movie"] as const;

type BibbiPublicationType = (typeof bibbiPublicationTypes)[number];

const isBibbiPublicationType = (item: unknown): item is BibbiPublicationType =>
  typeof item === "string" && (bibbiPublicationTypes as readonly string[]).includes(item);

export interface BibbiPublication {
  id: string;
  description?: string;
  creator: Creator[];
  publisher?: string;
  inLanguage?: string;
  edition?: string;
  genre: Genre[];
  identifier?: string;
  gtin13?: string;
  isbn?: string;
  placeOfPublication?: string;
  image?: Image;
  about: Subject[];
  webdewey: string[];
  datePublished?: string;
  work?: PublicationWork;
  bibliographicRecord: BibliographicRecord;
  name?: string;
  bookFormat?: "AudiobookFormat" | "EBook" | "GraphicNovel" | "Hardcover" | "Paperback";
  "@type"?: BibbiPublicationType;
}

const isBibbiPublication = (item: unknown): item is BibbiPublication =>
  typeof item === "object" &&
  typeof item?.["id"] === "string" &&
  (!item?.["@type"] || isBibbiPublicationType(item?.["@type"]));

interface BibbiPublicationsResponse {
  records: BibbiPublication[];
  total: number;
}

const isBibbiPublicationsResponse = (data: unknown): data is BibbiPublicationsResponse =>
  typeof data === "object" &&
  typeof data?.["total"] === "number" &&
  Array.isArray(data?.["records"]) &&
  !!data?.["records"]?.every(isBibbiPublication);

interface QueryParams {
  query?: string;
  limit?: string;
  mode?: string;
}

export type BibbiWork = {
  "@type": "Work";
  originalLanguage?: LocalizedValue;
  workTypes?: { label: string; uri: string }[];
  creator?: { name: string; id: string }[];
  originalYear?: string;
  id: string;
  nameParts?: { partNumber?: string; partTitle?: string; type?: string; title: string };
  name?: string;
  publications?: BibbiPublication[];
};

const isBibbiWork = (item: unknown): item is BibbiWork =>
  typeof item === "object" && typeof item?.["id"] === "string" && item?.["@type"] === "Work";

interface BibbiWorksResponse {
  works: BibbiWork[];
  total: number;
}

const isBibbiWorksResponse = (data: unknown): data is BibbiWorksResponse =>
  typeof data === "object" &&
  typeof data?.["total"] === "number" &&
  Array.isArray(data?.["works"]) &&
  !!data?.["works"]?.every(isBibbiWork);

const handlePublicationsImageUrls = (publications: BibbiPublication[] | undefined) =>
  publications?.map((record: BibbiPublication) => {
    if (record.image?.url) {
      // Until the redirect URLs from the old Pimcore service get CORS headers, we simulate the redirect here
      record.image.url = record.image.url.replace("https://aja", "https://media.aja");
    }
    if (record.image?.thumbnailUrl) {
      // Until the redirect URLs from the old Pimcore service get CORS headers, we simulate the redirect here
      record.image.thumbnailUrl = record.image.thumbnailUrl.replace("https://aja", "https://media.aja");
    }
    return record;
  });

export class BibbiApi {
  static worksUrl(params: QueryParams): string {
    const searchParams = new URLSearchParams({ ...params });
    return `https://bibliografisk.bs.no/v1/works?${searchParams.toString()}`;
  }

  static async works(params: QueryParams): Promise<{ works?: BibbiWork[]; error?: string }> {
    const url = this.worksUrl(params);
    const response = await fetch(url, {
      headers: {
        "X-User-Agent": "Libry Content",
      },
    });
    const body = await response.text();

    if (!response.ok) {
      try {
        const data = JSON.parse(body);
        return { error: data.error };
      } catch {
        return { error: body };
      }
    }

    try {
      const data = JSON.parse(body);

      if (!isBibbiWorksResponse(data)) {
        return { error: `Unexpected response from bibbi: "${JSON.stringify(data)}"` };
      }

      const works = data.works.map((work) => ({
        ...work,
        publications: handlePublicationsImageUrls(work?.publications),
      }));

      return { works };
    } catch (err) {
      return { error: JSON.stringify(err) };
    }
  }

  static async publications(params: QueryParams): Promise<{ publications?: BibbiPublication[]; error?: string }> {
    const searchParams = new URLSearchParams({ ...params });
    const url = `https://bibliografisk.bs.no/v1/publications?${searchParams.toString()}`;

    const response = await fetch(url, {
      headers: {
        "X-User-Agent": "Libry Content",
      },
    });
    const body = await response.text();

    if (!response.ok) {
      try {
        const data = JSON.parse(body);
        return { error: data.error };
      } catch {
        return { error: body };
      }
    }

    const data = JSON.parse(body);

    if (!isBibbiPublicationsResponse(data)) {
      return { error: `Unexpected response from bibbi: "${JSON.stringify(data)}"` };
    }

    const publications = handlePublicationsImageUrls(data.records);

    return { publications };
  }
}

const addPrefix =
  (prefix = "") =>
  (item: string): string =>
    prefix ? `${prefix}:${item}` : item;

const concatenateIsbns = (isbns: string | string[], prefix = ""): string =>
  Array.isArray(isbns) ? isbns.map(addPrefix(prefix)).join(" OR ") : addPrefix(prefix)(isbns);

export const findBibbiPublicationsByIsbn = async (isbns: string[] | string | undefined, limit = "1") => {
  if (!isbns) return { error: "Did not supply isbn" };

  const query = concatenateIsbns(isbns, "isbn");
  return BibbiApi.publications({ mode: "advanced", query, limit });
};

export const findBibbiWorksByIsbn = async (isbns: string[] | string | undefined, limit = "1") => {
  if (!isbns) return { error: "Did not supply isbn" };

  const query = concatenateIsbns(isbns);
  return BibbiApi.works({ mode: "advanced", query, limit });
};

export const bibbiWorksByIsbnUrl = (isbns: string[] | string | undefined, limit = "1"): string | undefined => {
  if (!isbns) {
    console.error("Did not supply isbn when getting bibbi works by isbn URL");
    return;
  }

  const query = concatenateIsbns(isbns);
  return BibbiApi.worksUrl({ mode: "advanced", query, limit });
};
